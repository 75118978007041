<template>
  <template v-for="(consent, index) in consents" :key="index">

    <section v-if="consent === 'overtime'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">시간외 근무과정안내</h4>
        <template v-if="isMobile">
          <div v-show="isMobile" class="header-sub">
            <p class="subtext-sm">본 과정은 아래와 같이 상황에 따라 주말 최종 평가시간에 대해 시간외 근무를 부여하는 과정입니다.</p>
          </div>
        </template>
        <template v-else>
          <p class="subtext">본 과정은 아래와 같이 상황에 따라 주말 최종 평가시간에 대해 시간외 근무를 부여하는 과정입니다.</p>
        </template>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title">휴/정직자</div>
            <div class="desc">
              <p>신청가능</p>
            </div>
            <div class="desc">
              <p>평가일기준, 휴/정직자는 시간외근무 미부여</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">휴일 시간외 근무불가자(임신사실등록,만18세미만)</div>
            <div class="desc">
              <p>신청불가(연수신청 후 해당사유 발생 시 사전 안내후 불참처리)</p>
            </div>
            <div class="desc">
              <p>-</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">상기 외 일반직원</div>
            <div class="desc">
              <p>신청가능</p>
            </div>
            <div class="desc">
              <p>당해직급 직무필수 미충족 직원이 주말최종평가 응시시 일괄부여(단, 평가일 기준 휴/정직자 제외)</p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="overtime"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:220px">
                  <col style="width:auto;">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">휴/정직자</p>
                  </th>
                  <td><p class="td-text">연수 신청 가능</p></td>
                  <td><p class="td-text">평가일 기준<br>휴/정직자는 시간외 근무 미부여</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">휴일 시간외 근무불가자<br>(임신사실등록,만18세미만)</p>
                  </th>
                  <td><p class="td-text">연수 신청 불가<br>(연수신청 후 해당사유 발생 시 사전 안내 후 연수 불참처리)</p></td>
                  <td><p class="td-text">-</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">상기 외 일반직원</p>
                  </th>
                  <td><p class="td-text">연수 신청 가능</p></td>
                  <td><p class="td-text">당해직급 직무필수 미충족 직원이 주말최종평가 응시시 일괄부여<br>(단, 평가일 기준 휴/정직자 제외)</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="overtime"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <section v-else-if="consent === 'personal'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">개인정보활용동의서</h4>
        <template v-if="isMobile">
          <div v-show="isMobile" class="header-sub">
            <p class="subtext-sm">
              연수와 관련된 서비스를 제공할 목적으로 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 22조, 제24조, 제25조, 제58조에서 정하는 바에 따라 학습자의 정보를 수집 및
              활용하기 위한 동의를 얻고자 합니다.
            </p>
          </div>
        </template>
        <template v-else>
          <p class="subtext">
            연수와 관련된 서비스를 제공할 목적으로 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 22조, 제24조, 제25조, 제58조에서 정하는 바에 따라 학습자의 정보를 수집 및
            활용하기 위한 동의를 얻고자 합니다.</p>
        </template>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title">정보를 제공받는 자</div>
            <div class="desc">
              <p>{{ conParams.prvdrNm }}</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">제공 정보</div>
            <div class="desc">
              <p>성명, 직원번호, 직급, 소속부점, 휴대전화번호, 이메일주소, 우편물 수령지 주소</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">정보이용목적</div>
            <div class="desc">
              <p>1. 고용보험 환급업무 : 성명, 주민등록번호, 직급</p>
              <p>2. 교재, 참고도서제공 : 소속부점, 우편물 수령지, 전화번호</p>
              <p>3. 학습자의 진도관리 : 성명, 직원번호, 전화번호, 이메일주소</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">정보를 제공받는 자의 정보보유 및 이용기간</div>
            <div class="desc">
              <p>교육이력을 포함한 개인정보는 노동부 지침에 따라 최대 3년간보관합니다.</p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="personal"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:180px">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">정보를 제공받는 자</p>
                  </th>
                  <td><p class="td-text">{{ conParams.prvdrNm }}</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">제공 정보</p>
                  </th>
                  <td><p class="td-text">성명, 직원번호, 직급, 소속부점, 휴대전화번호, 이메일주소, 우편물 수령지 주소</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">정보이용목적</p>
                  </th>
                  <td>
                    <p class="td-text">1. 고용보험 환급업무 : 성명, 주민등록번호, 직급</p>
                    <p class="td-text">2. 교재, 참고도서제공 : 소속부점, 우편물 수령지, 전화번호</p>
                    <p class="td-text">3. 학습자의 진도관리 : 성명, 직원번호, 전화번호, 이메일주소</p>
                  </td>
                </tr>
                <tr>
                  <th><p class="th-title">정보를 제공받는 자의 <br>정보보유 및 이용기간</p>
                  </th>
                  <td><p class="td-text">교육이력을 포함한 개인정보는 노동부 지침에 따라 최대 3년간 보관합니다.</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="personal"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_02"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <!-- popup-section:통신연수 미수료 기준 적용 확인 -->
    <section v-else-if="consent === 'communication'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">통신연수 미수료 기준 적용 확인</h4>
      </header>
      <div class="section-content" :class="{'agree-wrap': !isMobile}">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="desc m-0">
              <p class="text">한국금융연수원에서 주관하는 일부 과정은 해당 기관의 수료강화 조치에 따라 주 1회 이상 홈페이지에서 주간 학습/평가에 참여하셔야 합니다.
                              자세한 수료조건은 과정안내를 참고하시기 바랍니다.</p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_03"
                       value="communication"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content segment-box">
            <p class="text">한국금융연수원에서 주관하는 일부 과정은 해당 기관의 수료강화 조치에 따라 주 1회 이상 홈페이지에서 주간 학습/평가에 참여하셔야 합니다.</p>
            <p class="text">자세한 수료조건은 과정안내를 참고하시기 바랍니다.</p>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_03" value="communication"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_03"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <!-- popup-section:교육훈련비 환입제도 적용확인 -->
    <section v-else-if="consent === 'training'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">교육훈련비 환입제도 적용확인</h4>
        <template v-if="isMobile">
          <div v-show="isMobile" class="header-sub">
            <p class="subtext-sm">
              본 과정의 수강에 필요한 교육비는 전액 은행에서 지원합니다. 단, 과정 미 이수시에는 교육훈련비 환입제도에 의해 교육비를 본인이 부담하셔야 합니다.
            </p>
          </div>
        </template>
        <template v-else>
          <p class="subtext">본 과정의 수강에 필요한 교육비는 전액 은행에서 지원합니다. 단, 과정 미 이수시에는 교육훈련비 환입제도에 의해 교육비를 본인이 부담하셔야 합니다.</p>
        </template>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title"> 외부위탁연수</div>
            <div class="desc">
              <p>1인당 교육훈련비 전액 환입</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">자체운영연수</div>
            <div class="desc">
              <p>연수기간별 고용보험 환급 해당액</p>
            </div>
          </div>
          <div class="content-item">
            <div class="desc m-0">
              <p>본 과정의 교육훈련비 환입 예상액은 {{ numberComma(conParams.unfiRmbrAmt) }}원 으로 실제 환입시 금액과 차이가 날 수 있습니다. 환입 시기는 연수종료 익월 이후에 개인별 통지 후 환입처리 됩니다.
              </p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_05" value="training"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:180px">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">외부위탁연수</p>
                  </th>
                  <td><p class="td-text">1인당 교육훈련비 전액 환입</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">자체운영연수</p>
                  </th>
                  <td><p class="td-text">연수기간별 고용보험 환급 해당액</p></td>
                </tr>
                <tr>
                  <td colspan="2"><p class="td-text">본 과정의 교육훈련비 환입 예상액은 {{ numberComma(conParams.unfiRmbrAmt) }}원 으로 실제 환입시 금액과 차이가 날 수 있습니다. 환입 시기는
                    연수종료
                    익월 이후에 개인별 통지 후 환입처리 됩니다.</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_05" value="training"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_05"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <!-- popup-section:숙소신청관련 메시지 -->
    <section v-if="consent === 'stay'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">숙박신청안내</h4>
      </header>
      <div class="section-content" :class="{'agree-wrap': !isMobile}">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="desc m-0">
              <p class="text">숙박을 신청하는 경우, 원칙적으로 외출만 가능하며 외출로 인하여 발생하는 모든사고에 대해서 본인이 전적으로 책임을 부담합니다.
                              외출은 당일 오후 22시까지 귀환하여야 하며, 음주/학습태도 불량 등 연수원의 준수사항을 위한하는 경우,
                              소속부점장에게 통보하고 사유에 따라 조기퇴소 및 미수료(연수비용환입)처리 될 수 있습니다.<br><br></p>
              <p class="text">[연수규정 제11조 2항]</p>
              <p class="text">연수대상자는 연수처의 제반규정을 준수하고 은행의 명예를 훼손하는 행위를 하여서는 안되며,
                              해당연수를 충실히 이수하고 연수를 통하여 습득한 내용을 활용하여 은행의 발전을 기여하여야 한다.</p>
              <p class="text">[연수규정 제39조 3호]</p>
              <p class="text">필요한 경우 연수 참가자에 대하여 연수중단 명령과 함께 현업복귀를 명할 수 있다.</p>
              <p class="text">[연수규정 제39조 6호]</p>
              <p class="text">효율적인 연수운영을 위하여 연수운영에 대한 규칙을 정하여 운영할 수 있다.</p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="stay"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content segment-box">
            <p class="text">숙박을 신청하는 경우, 원칙적으로 외출만 가능하며 외출로 인하여 발생하는 모든사고에 대해서 본인이 전적으로 책임을 부담합니다.</p>
            <p class="text">외출은 당일 오후 22시까지 귀환하여야 하며, 음주/학습태도 불량 등 연수원의 준수사항을 위한하는 경우,</p>
            <p class="text">소속부점장에게 통보하고 사유에 따라 조기퇴소 및 미수료(연수비용환입)처리 될 수 있습니다.<br><br></p>
            <p class="text">[연수규정 제11조 2항]</p>
            <p class="text">연수대상자는 연수처의 제반규정을 준수하고 은행의 명예를 훼손하는 행위를 하여서는 안되며,</p>
            <p class="text">해당연수를 충실히 이수하고 연수를 통하여 습득한 내용을 활용하여 은행의 발전을 기여하여야 한다.</p>
            <p class="text">[연수규정 제39조 3호]</p>
            <p class="text">필요한 경우 연수 참가자에 대하여 연수중단 명령과 함께 현업복귀를 명할 수 있다.</p>
            <p class="text">[연수규정 제39조 6호]</p>
            <p class="text">효율적인 연수운영을 위하여 연수운영에 대한 규칙을 정하여 운영할 수 있다.</p>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="stay"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <!-- popup-section:구 직무필수 신청 관련 메세지 -->
    <section v-if="consent === 'training_jikmu'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <div class="section-content" :class="{'agree-wrap': !isMobile}">
        <template v-if="isMobile">
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree" style="color:red; font-weight: bold; font-size: 15px; ">
              해당 과정은 재수강용 과정임을 확인하였음.
              <div class="kb-form-check" style="text-align: right">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="stay"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="stay"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_01"><span class="text" style="color:red; font-weight: bold; font-size: 18px;">해당 과정은 재수강용 과정임을 확인하였음.</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <section v-else-if="consent === 'global'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">개인정보활용동의서</h4>
        <template v-if="isMobile">
          <div v-show="isMobile" class="header-sub">
            <p class="subtext-sm">
              연수와 관련된 서비스를 제공할 목적으로 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 22조, 제24조, 제25조, 제58조에서 정하는 바에 따라 학습자의 정보를 수집 및 활용하기 위한 동의를 얻고자 합니다.
            </p>
          </div>
        </template>
        <template v-else>
          <p class="subtext">
            연수와 관련된 서비스를 제공할 목적으로 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 22조, 제24조, 제25조, 제58조에서 정하는 바에 따라 학습자의 정보를 수집 및
            활용하기 위한 동의를 얻고자 합니다.</p>
        </template>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title">정보를 제공받는 자</div>
            <div class="desc">
              <p>한국표준협회</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">제공 정보</div>
            <div class="desc">
              <p>성명, 직원번호, 소속부점, 직위, 직급, 휴대전화번호, 주민등록번호, 여권정보</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">정보이용목적</div>
            <div class="desc">
              <p>1. 연수안내 및 운영 전반 : 성명, 직원번호, 소속부점, 직위, 직급, 휴대전화번호</p>
              <p>2. 항공권 발권 및 부수업무 : 성명, 휴대전화번호, 여권정보</p>
              <p>3. 여행자보험 가입 : 성명, 휴대전화번호, 주민등록번호</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">정보를 제공받는 자의 정보보유 및 이용기간</div>
            <div class="desc">
              <p>교육이력을 포함한 개인정보는 노동부 지침에 따라 최대 3년간 보관합니다.</p>
            </div>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              동의완료
              <div class="kb-form-check">
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="personal"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:180px">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">정보를 제공받는 자</p>
                  </th>
                  <td><p class="td-text">한국표준협회</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">제공 정보</p>
                  </th>
                  <td><p class="td-text">성명, 직원번호, 소속부점, 직위, 직급, 휴대전화번호, 주민등록번호, 여권정보</p></td>
                </tr>
                <tr>
                  <th><p class="th-title">정보이용목적</p>
                  </th>
                  <td>
                    <p class="td-text">1. 연수안내 및 운영 전반 : 성명, 직원번호, 소속부점, 직위, 직급, 휴대전화번호</p>
                    <p class="td-text">2. 항공권 발권 및 부수업무 : 성명, 휴대전화번호, 여권정보</p>
                    <p class="td-text">3. 여행자보험 가입 : 성명, 휴대전화번호, 주민등록번호</p>
                  </td>
                </tr>
                <tr>
                  <th><p class="th-title">정보를 제공받는 자의 <br>정보보유 및 이용기간</p>
                  </th>
                  <td><p class="td-text">교육이력을 포함한 개인정보는 노동부 지침에 따라 최대 3년간 보관됩니다.</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="agree-check">
            <div class="kb-form-check">
              <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="personal"
                     @change="emitChecked" :disabled="isView">
              <label class="kb-form-check-label" for="chk_agree_02"><span class="text">동의완료</span></label>
            </div>
          </div>
        </template>
      </div>
    </section>

    <!-- popup-section:CI 정보 동의서 -->
    <section v-else-if="consent === 'ci1'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">KB 국민은행 귀증</h4>
        <template v-if="isMobile">
          <div v-show="isMobile" class="header-sub">
            <p class="subtext-sm">
              * 귀 행과의 (금융)거래와 관련하여 귀행이 본인의 개인(신용)정보를 수집ㆍ이용하고자 하는 경우에는
              「신용정보의 이용 및 보호에 관한 법률」, 「개인정보보호법」 등 관계 법령에 따라 본인의 동의가 필요합니다.
            </p>
          </div>
        </template>
        <template v-else>
          <p class="subtext">* 귀 행과의 (금융)거래와 관련하여 귀행이 본인의 개인(신용)정보를 수집ㆍ이용하고자 하는 경우에는
            「신용정보의 이용 및 보호에 관한 법률」, 「개인정보보호법」 등 관계 법령에 따라 본인의 동의가 필요합니다.</p>
        </template>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title">수집ㆍ이용 목적</div>
            <div class="desc">
              <p>- 본인식별, 인증</p>
<!--              <p>- 온ㆍ오프라인 고객 연계</p>-->
              <p>- (금융)거래관계의 설정 여부 판단</p>
              <p>- (금융)거래관계의 설정ㆍ유지ㆍ이행ㆍ관리</p>
              <p>- 금융사고 조사, 분쟁 해결, 민원 처리</p>
            </div>
          </div>
          <div class="content-item">
            <div class="title">보유 및 이용기간</div>
            <div class="desc">
              <p style="font-size: 15px; font-weight: bold;">- 근로계약 종료일로부터 1년까지 보유ㆍ이용됩니다.</p>
<!--              <p>위 보유 기간에서의 (금융)거래 종료일이란 "당행과 거래중인 모든 계약(여ㆍ수신. 내ㆍ외국환, 카드 및 제3자 담보 제공 등) 해지 및 서비스(대여금고, 보호예수, 외국환거래지정, 인터넷뱅킹 포함 전자금융거래 등)가 종료된 날"을 말합니다.</p>-->
<!--              <p style="margin-top: 10px;">※ (금융)거래 종료일 후에는 금융사고 조사, 분쟁 해결, 민원 처리, 법량상 의무이행을 위한 목적으로만 보유 이용됩니다.</p>-->
            </div>
          </div>
          <div class="content-item">
            <div class="title">거부 권리 및 불이익</div>
            <div class="desc">
              <p>귀하는 동의를 거부할 수 있습니다. 다만, 위 개인(신용)정보 수집ㆍ이용에 관한 동의는 "(금융)거래 계약의 체결 및 이행을 위한" 필수적 사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및 유지가 가능합니다.</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:180px">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">수집ㆍ이용 목적</p>
                  </th>
                  <td><p class="td-text">- 본인식별, 인증</p>
<!--                    <p class="td-text">- 온ㆍ오프라인 고객 연계</p>-->
                    <p class="td-text">- (금융)거래관계의 설정 여부 판단</p>
                    <p class="td-text">- (금융)거래관계의 설정ㆍ유지ㆍ이행ㆍ관리</p>
                    <p class="td-text">- 금융사고 조사, 분쟁 해결, 민원 처리</p>
                  </td>
                </tr>
                <tr>
                  <th><p class="th-title">보유 및 이용기간</p>
                  </th>
                  <td><p class="td-text" style="font-size: 20px; font-weight: bold;">- 근로계약 종료일로부터 1년까지 보유ㆍ이용됩니다.</p>
<!--                    <p class="td-text">위 보유 기간에서의 (금융)거래 종료일이란 "당행과 거래중인 모든 계약(여ㆍ수신. 내ㆍ외국환, 카드 및 제3자 담보 제공 등) 해지 및 서비스(대여금고, 보호예수, 외국환거래지정, 인터넷뱅킹 포함 전자금융거래 등)가 종료된 날"을 말합니다.</p>-->
<!--                    <p class="td-text">※ (금융)거래 종료일 후에는 금융사고 조사, 분쟁 해결, 민원 처리, 법량상 의무이행을 위한 목적으로만 보유 이용됩니다.</p>-->
                  </td>
                </tr>
                <tr>
                  <th><p class="th-title">거부 권리 및 불이익</p>
                  </th>
                  <td>
                    <p class="td-text">귀하는 동의를 거부할 수 있습니다. 다만, 위 개인(신용)정보 수집ㆍ이용에 관한 동의는 "(금융)거래 계약의 체결 및 이행을 위한" 필수적 사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및 유지가 가능합니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </section>

    <section v-else-if="consent === 'ci2'" :class="{'content-section': isMobile, 'popup-section': !isMobile}">
      <header class="section-header">
        <h4 class="title">수집ㆍ이용 항목</h4>
      </header>
      <div class="section-content agree-wrap">
        <template v-if="isMobile">
          <div class="content-item">
            <div class="title">개인(신용)정보</div>
            <div class="title">└ 일반개인정보</div>
            <div class="desc">
              <p style="font-weight: bold; font-size: 17px;">연계정보(CI)*</p>
            </div>
            <p class="td-text" style="margin-top: 5px; font-size: 13px;">* 연계정보(CI) : 온라인상 이용자 식별과 온ㆍ오프라인 서비스 연계 등을 위해 주민등록번호를 복원 및 환원이 불가하게 암호화한 값</p>
            <p class="td-text" style="margin-top: 10px;">위 개인(신용)정보 수집ㆍ이용에 동의하십니까?</p>
          </div>
          <div class="content-item">
            <label for="chk_agree_01" class="item-agree">
              <div class="kb-form-check">
                동의하지 않음&nbsp;
                <input type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="training"
                       @change="notAgreeChecked" :disabled="isView">
                &nbsp;&nbsp;
                동의함&nbsp;
                <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="training"
                       @change="emitChecked" :disabled="isView">
              </div>
            </label>
          </div>
        </template>
        <template v-else>
          <div class="agree-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:180px">
                  <col style="width:auto;">
                </colgroup>
                <tbody>
                <tr>
                  <th><p class="th-title">개인(신용)정보</p>
                    <p class="th-title">└ 일반개인정보</p>
                  </th>
                  <td><p class="td-text" style="font-weight: bold; border-bottom: 2px solid #e8e8e8;">연계정보(CI)*</p>
                    <p class="td-text" style="margin-top: 10px;">위 개인(신용)정보 수집ㆍ이용에 동의하십니까?</p>
                    <div class="agree-check" style="margin-top: -25px;">
                      <div class="kb-form-check">
                        <input type="checkbox" class="kb-form-check-input" id="chk_agree_01" value="personal"
                               @change="notAgreeChecked" :disabled="isView">
                        <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의하지 않음</span></label>
                      </div>
                      <div class="kb-form-check">
                        <input v-model="checked" type="checkbox" class="kb-form-check-input" id="chk_agree_02" value="personal"
                               @change="emitChecked" :disabled="isView">
                        <label class="kb-form-check-label" for="chk_agree_02"><span class="text">동의함</span></label>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="td-text" style="margin: 10px;">* 연계정보(CI) : 온라인상 이용자 식별과 온ㆍ오프라인 서비스 연계 등을 위해 주민등록번호를 복원 및 환원이 불가하게 암호화한 값</p>
            </div>
          </div>
        </template>
      </div>
    </section>


  </template>

</template>

<script>
import {ref} from 'vue';
import navigationUtils from '@/assets/js/navigationUtils';
import {numberComma} from '@/assets/js/util';

export default {
  name: 'CourseConsent',
  props: {
    modelValue: Array,
    consents: {
      required: true,
      type: Array,
    },
    isView: Boolean,
    conParams: Object
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const isMobile = navigationUtils.any();
    const checked = ref([]);
    const conVals = ref({});
    // console.log(props.conParams, props.consents);
    if (props.isView) {
      props.consents.forEach(x => {
        checked.value.push(x);
      });
    }
    const isnotAgreeView = ref(false);

    const emitChecked = () => {
      if (props.consents[0] === 'ci1') {
        document.getElementById('chk_agree_01').checked = false;
      }
      emit('update:modelValue', checked.value);
    };

    const notAgreeChecked = () => {
      document.getElementById('chk_agree_02').checked = false;
      checked.value.pop();
      emit('update:modelValue', checked.value);
    };

    emit('update:modelValue', []);

    return {
      checked,
      emitChecked,
      notAgreeChecked,
      isMobile,
      conVals,
      numberComma,
      isnotAgreeView,
    };

  },
};
</script>